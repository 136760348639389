import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './pages/home';
import { Footer } from './components/footer';
import { UserSignup } from './pages/user/userSignup';
import { BuyTickets } from './pages/user/buyTickets';
import { UserLogin } from './pages/user/userLogin';
import { UserHeader } from './pages/user/userHeader';
import { Track } from './pages/user/track';
import { Pickups } from './pages/partner/pickups';
import { Info } from './pages/partner/info';
import { PartnerHeader } from './pages/partner/partnerHeader';
import { PartnerLogin } from './pages/partner/partnerLogin';
import { EmailVerified } from './pages/emailVerified';
import { PrivacyPolicy } from './pages/privacyPolicy';
import { Terms } from './terms';

const UserHeaderWithLocation = () => {
  const location = useLocation();
  const currentPage = location.pathname === "/track" ? "Track" : "Buy";
  return <UserHeader currentPage={currentPage} />;
};

const PartnerHeaderWithLocation = () => {
  const location = useLocation();
  const currentPage = location.pathname === "/pickups" ? "pickups" : "info";
  return <PartnerHeader currentPage={currentPage} />;
};

function App() {
  return (
    <Router>
      <div className="relative">
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/emailVerified" element={<EmailVerified />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          {/* <Route path="/signup" element={<UserSignup />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/partnerLogin" element={<PartnerLogin />} />

          <Route path="/" element={<UserHeaderWithLocation />}>
            <Route path="buyTickets" element={<BuyTickets />} />
            <Route path="track" element={<Track />} />
          </Route>
          
          <Route path="/" element={<PartnerHeaderWithLocation />}>
            <Route path="pickups" element={<Pickups />} />
            <Route path="info" element={<Info />} />
          </Route> */}

          <Route path="*" element={<Home />} />
          
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
